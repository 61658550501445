<template>
    <div class="row justify-content-center">
        <div class="col-lg-4">
            <b-form>
                <b-form-group
                        id="fieldset-1"
                        description="學號、學生證號或是身份證字號"
                        label="尋找學生"
                        label-for="input-1"
                >
                    <b-input-group class="mt-3">
                        <template v-slot:append>
                            <b-button>送出</b-button>
                        </template>
                        <b-form-input trim/>
                    </b-input-group>
                </b-form-group>
            </b-form>
            <b-card title="拍張照片吧" sub-title="請在送出前確保照片清晰可辨識。" ref="father">
                <div class="camera">
                    <video @canplay="initialVideo" id="video" ref="video">初始化等待</video>
                    <b-button id="startbutton" @click.once="getCamera">Initial Camera</b-button>
                    <b-button @click="takePicture">拍照</b-button>
                    <b-button @click="$refs.confirm.show()">顯示</b-button>
                    <canvas id="canvas" ref="canvas" v-show="false">
                    </canvas>
                </div>
            </b-card>
        </div>
        <div class="col">
            <b-table id="my-package-table"
                     striped
                     hover
                     :items="packages.items"
                     :fields="packages.fields"
                     :current-page="packages.currentPage"
                     :per-page="packages.perPage"
            >
                <template v-slot:row-details="row">
                    <ul>
                        <li>物流編號：{{row.item.more.serial}}</li>
                        <li>簽收時間：{{row.item.more.receive_time}}</li>
                        <li>物流業者：{{row.item.more.logistics}}</li>
                        <li>代領：{{row.item.more.friend_name}}</li>
                    </ul>
                    <p>備註：<br>{{row.item.more.remark}}</p>
                    <b-button v-if="row.item.packageStatus === '待領取'">請人代領</b-button>
                    <b-button variant="danger"  v-if="row.item.packageStatus === '待領取'">退件</b-button>
                    <b-button variant="danger" class="mt-3" v-if="row.item.packageStatus === '預登記'">刪除登記</b-button>
                </template>
                <template v-slot:cell(packageActions)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                        {{ row.detailsShowing ? '更少' : '更多'}} 操作
                    </b-button>
                </template>
            </b-table>
        </div>
        <b-modal ref="confirm" title="領取包裹" size="xl" @hidden="clearModal" @show="takePicture" hide-footer>
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <b-card
                            title="領取人資料"
                            :img-src="camera.picture"
                            img-alt="Image"
                            img-top
                            tag="article"
                            style="max-width: 20rem;"
                            class="mb-2"
                    >
                        <b-card-text>
                            務必確認領取人之相片與證件相符。
                            <b-form>
                                <b-form-group
                                        description="學號、學生證號或是身份證字號"
                                        label-for="input-2"
                                >
                                    <b-input-group class="mt-3">
                                        <template v-slot:append>
                                            <b-button>搜尋</b-button>
                                        </template>
                                        <b-form-input v-model="modal.searchInput" id="input-2" trim/>
                                    </b-input-group>
                                </b-form-group>
                            </b-form>
                        </b-card-text>
                        <b-button @click="takePicture" variant="primary" block>再拍一張照</b-button>
                    </b-card>
                </div>
                <div class="col">
                    <b-card
                            title="包裹資訊"
                    >
                        <b-card-text>
                            請確定刷到每件領取的包裹
                            <b-form>
                                <b-form-group
                                        description="包裹條碼"
                                        label-for="input-3"
                                >
                                    <b-input-group class="mt-3">
                                        <template v-slot:append>
                                            <b-button>搜尋</b-button>
                                        </template>
                                        <b-form-input v-model="modal.searchInput" id="input-3" trim/>
                                    </b-input-group>
                                </b-form-group>
                            </b-form>
                        </b-card-text>
                        <b-card-text>
                            <b-table striped hover :items="modal.items" :fields="modal.fields"></b-table>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
            <b-button class="mt-3" variant="primary"  block >領取包裹</b-button>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "TakePackage",
        data: function(){
            return {
                camera: {
                    picture: '',
                    tracks: null
                },
                modal: {
                    show: false,
                    searchInput: "",
                    studentId: "",
                    showStudentStr: "",
                    packages: [],
                    fields: [
                        {
                            key: 'packageId',
                            label: '包裹編號',
                            sortable: true
                        },
                        {
                            key: 'serial',
                            label: '物流號'
                        },
                        {
                            key: 'packageStatus',
                            label: '目前狀態'
                        },
                        {
                            key: 'recipient',
                            label: '收件人'
                        },
                        {
                            key: 'friend',
                            label: '代領'
                        },
                    ],
                    items: [
                        { packageId: 4107, serial: 2512578900154, packageStatus: '待領', recipient: '黃彥儒', friend: null }
                    ]
                },
                packages: {
                    fields: [
                        {
                            key: 'packageId',
                            label: '包裹編號',
                            sortable: true
                        },
                        {
                            key: 'packagePlace',
                            label: '放置地點'
                        },
                        {
                            key: 'package_rTime',
                            label: '建單時間'
                        },
                        {
                            key: 'packageStatus',
                            label: '目前狀態'
                        },
                        {
                            key: 'packageActions',
                            label: '操作'
                        }
                    ],
                    items: [
                        { packageId: 4107, packagePlace: "包裹室", package_rTime: "2020/04/24 19:37", packageStatus: '待領取', more:{
                                remark: "下次請寫房號",
                                close: "2020/04/24 19:37",
                                receive_time: "2020/04/24 19:37",
                                serial: "0055185874512",
                                logistics: "白貓宅急便",
                                friend_name: null
                            } },
                        { packageId: 8112, packagePlace: "包裹室", package_rTime: "2020/04/24 19:37", packageStatus: '已退件', more:{
                                remark: "下次請寫房號",
                                close: "2020/04/24 19:37",
                                receive_time: "2020/04/24 19:37",
                                serial: "0055185874512",
                                logistics: "白貓宅急便",
                                friend_name: null
                            } },
                        { packageId: 4385, packagePlace: "交誼廳", package_rTime: "2020/04/24 19:37", packageStatus: '已代領', more:{
                                remark: "下次請寫房號",
                                close: "2020/04/24 19:37",
                                receive_time: "2020/04/24 19:37",
                                serial: "0055185874512",
                                logistics: "白貓宅急便",
                                friend_name: null
                            } },
                        { packageId: null, packagePlace: null, package_rTime: "2020/04/24 19:37", packageStatus: '預登記', more:{
                                remark: "下次請寫房號",
                                close: "2020/04/24 19:37",
                                receive_time: "2020/04/24 19:37",
                                serial: "0055185874512",
                                logistics: "白貓宅急便",
                                friend_name: null
                            } },
                    ],
                    currentPage: 1,
                    perPage: 10,
                },
            }
        },
        methods: {
            getCamera(){
                navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                    .then((stream) => {
                        this.$refs.video.srcObject = stream;
                        this.$refs.video.play();
                        this.camera.tracks = stream.getTracks();
                    })
                    .catch(function(err) {
                        window.console.log("An error occured! " + err);
                    });
            },
            initialVideo(){
                let height = this.$refs.video.videoHeight / (this.$refs.video.videoWidth/this.fatherWidth);
                this.$refs.video.setAttribute('width', this.fatherWidth);
                this.$refs.video.setAttribute('height', height);
                this.$refs.canvas.setAttribute('width', this.fatherWidth);
                this.$refs.canvas.setAttribute('height', height);
            },
            takePicture() {
                let height = this.$refs.video.videoHeight / (this.$refs.video.videoWidth/this.fatherWidth);
                let context = this.$refs.canvas.getContext('2d');
                if (this.fatherWidth && height) {
                    this.$refs.canvas.width = this.fatherWidth;
                    this.$refs.canvas.height = height;
                    context.drawImage(this.$refs.video, 0, 0, this.fatherWidth, height);
                    this.camera.picture = this.$refs.canvas.toDataURL();
                } else {
                    this.clearPhoto();
                }
            },
            clearPhoto() {
                let context = this.$refs.canvas.getContext('2d');
                context.fillStyle = "#AAA";
                context.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

                let data = this.$refs.canvas.toDataURL('image/png');
                this.$refs.photo.setAttribute('src', data);
            },
            clearModal(){
                this.modal = {
                    show: false,
                    searchInput: "",
                    word: "",
                    showStudentStr: "",
                    packages: []
                }
            }
        },
        computed: {
            fatherWidth: function () {
                return this.$refs.father.offsetWidth-48
            },
            blobPicture: function () {
                const blobBin = atob(this.$refs.canvas.toDataURL().split(',')[1]);
                const array = [];
                for (let i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i))
                }
                return new Blob([new Uint8Array(array)], { type: 'image/png' });
            }
        },
        beforeDestroy: function () {
            this.camera.tracks.forEach(function(track) {
                track.stop();
            });
        }
    }
</script>

<style scoped>

</style>